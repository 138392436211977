import React from "react";

const RegisterButton = ({ Type, UniqueID, className }) => {
  const type = Type === "sales" ? "Sales" : Type === "lettings" ? "Rental" : null;
  const agentID = type === "Sales" ? "RFS" : type === "Rental" ? "RFR" : null;
  const uniqueID = UniqueID?.split("-").pop() || "";

  console.log({ type, agentID, uniqueID, className });

  const handleRegister = () => {
    if (!type || !uniqueID) return;

    const url = `https://book.inspectrealestate.co.uk/RegisterUK?Source=Website&Type=${type}&agentID=${agentID}-CollegeandCountyP&uniqueID=${uniqueID}`;

    // Google Tag Manager Event
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "Register Btn Click",
        formType: "Register",
        formId: "Register Btn",
        formName: "Register Form",
        formLabel: `Type: ${type}, AgentID: ${agentID}, UniqueID: ${uniqueID}`,
      });
    }

    window.open(url, "_blank", "noopener,noreferrer");
  };

  return <a className={className} onClick={handleRegister}>Register a viewing</a>;
};

export default RegisterButton;